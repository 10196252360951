import request from '../../utils/request'


//获取今日概况累计数据
export function getNowDay(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/home/main',
  })
}

// 获取营收统计接口
// true 本月 false 本周
export function getRevenue(type: boolean): Promise<any> {
  return request({
    method: "GET",
    url: 'manage/home/revenue/' + type
  })
}
//今日概况+历史数据
export function getCount(): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/home/getCount',
  })
}


// 近7天预约人数统计图
export function getReservationCount(params: { day: any }): Promise<any> {
  return request({
    method: 'GET',
    url: 'manage/home/getReservationCount?day='+params.day,
  })
}
