


































































































import { Component, Vue } from 'vue-property-decorator';
import { getCount, getReservationCount } from '@/serve/home/index';
@Component({
  components: {},
  name: 'home',
})
export default class Home extends Vue {
  public btnSomeOne = true;
  public motherFuck = 1;
  public orderNum = '';
  public orderPay = '';
  public leftWeekData = '';
  public leftMonthData = '';
  public nowDayData = {
    homeAddDate: {
      checkReport: '',
      counselNum: '',
      realNameVip: '',
    },
    homeToDay: {
      addVip: '',
      counselNum: '',
      orderNum: '',
      orderPay: '',
    },
  };
  public mook: string[] = [];
  public mookData: string[] = [];
  // echarts传入数据
  public async created() {
    await this.getList();
    await this.changeDay(this.leftWeekData);
  }
  public mounted() {
    this.getCharts();
  }
  // 获取当前页面数据
  public async getList() {
    const res = await getCount();
    this.nowDayData = res.data;
    console.log("nowDayData",this.nowDayData);
    const params = {
      day:7
    }
    this.leftWeekData = await getReservationCount(params);
    console.log("具体数据",this.leftWeekData);
    
    
  }
  // 切换周月方法
  public changeDay(thisData: any) {
    const data = thisData.data;
    // this.orderNum = data.orderNum;
    // this.orderPay = data.orderPay;
    const timeArr = [];
    const valueArr = [];
    for (const key in data) {
      timeArr.push(data[key].date);
      valueArr.push(data[key].count);
    }
    this.mook = timeArr; // x轴数据
    this.mookData = valueArr; // y轴值
  
    this.getCharts();
  }
  // echart 的方法
  public getCharts(): void {
    const myChart = this.$echarts.init(this.$refs.myChart as HTMLCanvasElement);
    myChart.setOption({
      legend: {
        show: true,
      },
      toolbox: {},
      title: {
        // text: '营收统计(元)',
        textStyle: {
          fontSize: 15,
          fontWeight: 500,
        },
      },
      tooltip: {},
      xAxis: {
        name: '日期',
        data: this.mook,
        axisTick: {
          show: false,
        },
      },
      yAxis: {
        yxisTick: {
          show: false,
        },
      },
      series: [
        {
          type: 'line',
          data: this.mookData,
          color: '#38A28A',
          symbolSize: 10,
        },
      ],
    });
    setTimeout(function () {
      window.onresize = function () {
        myChart.resize();
      };
    }, 200);
  }
}
